import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BgImg from "gatsby-background-image-es5"
import { Flex, Box, Columns, Text } from "@sqymagma/elements"
import Tick from "../components/tick"
import Btn from "../components/button"
import { Link } from "gatsby"

const BigBanner = ({ children, cta, buttonlink, tagidbot }) => {
  const imgs = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "foto-baner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Box>
      <Columns cols={[1, 2]} hs="s">
        <Flex
          bg="brand02"
          flexDirection="column"
          justifyContent="center"
          align-Items="center"
          minHeight={["100vw", "50vw"]}
        >
          <Flex
            px={["spc2", "spc4", "spc4", "spc4", "spc12"]}
            textAlign="left"
            color="text01"
            flexDirection="column"
            justifyContent="left"
          >
            <Text textStyle="h1">{children}</Text>
            <Tick />

            <Btn
              as="a"
              type="link"
              href={buttonlink}
              margin="0px"
              size={("small", "big")}
              width={("180px", "220px")}
              id={tagidbot}
            >
              {cta}
            </Btn>
          </Flex>
        </Flex>
        <BgImg imgStyle={{ objectFit: "fit" }} fluid={imgs.placeholderImage.childImageSharp.fluid}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            align-Items="center"
            minHeight={["100vw", "50vw"]}
          ></Flex>
        </BgImg>
      </Columns>
    </Box>
  )
}

export default BigBanner
