import React, { useState } from "react"
import { Flex, Text } from "@sqymagma/elements"
import Tick from "../components/tick"
import Btn from "../components/button"
import SubscribeModal from "./m26_subscribeModal"

const LeaveData = ({ data, tagidsubemail, tagidsubllamada }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Flex
        bg="bg01"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        py={["spc4", "spc6", "spc8", "spc12", "spc16"]}
      >
        <Text textStyle="h3" color="text01" width={["250px", "420px", "530px"]}>
          {data.title}
        </Text>
        <Tick align="center" />
        <Text
          textStyle="subtitle"
          color="text01"
          mb={["16px", "24px", "32px", "40px"]}
          width={["250px", "420px", "530px"]}
        >
          {data.text}
        </Text>
        <Btn
          size="regular"
          width="200px"
          height="56px"
          onClick={() => setOpen(true)}
          id={data.idboton}
        >
          {data.cta}
        </Btn>
        {data.subtext !== "" && (
          <Text
            textStyle="p2"
            color="text03"
            my={["16px", "24px", "32px", "40px"]}
            width={["250px", "420px", "530px"]}
          >
            {data.subtext}
          </Text>
        )}
      </Flex>
      {open && (
        <SubscribeModal
          setClose={() => setOpen(false)}
          tagidsubemail={tagidsubemail}
          tagidsubllamada={tagidsubllamada}
        />
      )}
      {/* <AnimatePresence>
        {open && (
          <>
            <motion.div initial={{ y: 50 }} animate={{ y: 0 }} exit={{ y: 30 }}>
              <SubscribeModal setClose={() => setOpen(false)} />
            </motion.div>
          </>
        )}
      </AnimatePresence> */}

      {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {open && <SubscribeModal setClose={() => setOpen(false)} />}
      </motion.div> */}
    </>
  )
}

export default LeaveData
