const data = {
  title: "¿Cómo podemos ayudarte?",
  text: "Si necesitas información déjanos tus datos y nos pondremos en contacto contigo",
  subtext1: "Si prefieres llámanos al",
  number: "915613719",
  subtext2: "o te llamamos nosotros",
  cta: "Contacto",
}

export default data
