import { Flex, Stack, Text } from "@sqymagma/elements"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import ReactDOM from "react-dom"
import BtnNav from "../components/buttonNav"
import Tick from "../components/tick"
import SubscribeForm from "./SubscribeForm"

const SubscribeModal = ({ setClose, tagidsubemail, tagidsubllamada }) => {
  return ReactDOM.createPortal(
    <Flex
      bg="bg01"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex={999999}
    >
      <BtnNav
        onClick={() => setClose()}
        position="absolute"
        right={["24px", "40px"]}
        top={["24px", "40px"]}
        fill="brand01"
      />
      <AnimatePresence>
        <motion.div
          key="subscribe-modal"
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <Text textStyle="h3" color="text01">
              Suscríbete a nuestra newsletter
            </Text>
            <Tick align="center" />
            <Text
              textStyle="p1"
              color="text03"
              width={["255px", "455px", "530px"]}
              mb={["24px", "64px", "40px", "48px", "64px"]}
            >
              Te mandaremos nuevas oportunidades de inversión de adquisición de locales para
              cambiarles el uso a vivienda.
            </Text>
            <SubscribeForm
              width={["290px", "600px", "768px"]}
              setClose={setClose}
              buttonWidth={["150px", "150px", "200px"]}
              buttonText="Terminar"
              buttonPosition={["static", "absolute"]}
              border="1px solid #777777"
              tagidsubemail={tagidsubemail}
            />

            <Flex
              flexDirection={["column", "row"]}
              width={["240px", "370px", "450px"]}
              height="72px"
              bg={["bg01", "bg02"]}
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              my={["70px", "60px"]}
            >
              <Text textStyle="p_t" color="text03" m={["0", "10px", "10px", "15px", "20px"]}>
                Si lo prefieres llámanos
              </Text>
              <Text color="brand01" textStyle="subtitle" m={["0", "10px"]}>
                <a href="tel:915 613 719" tagidsubllamada={tagidsubllamada}>
                  915 613 719
                </a>
              </Text>
            </Flex>
          </Stack>
        </motion.div>
      </AnimatePresence>
    </Flex>,
    document.getElementById("modal")
  )
}

export default SubscribeModal
