import React from "react"

import Layout from "../modules/layout"
import BigBanner from "../modules/m18_bigBanner"
import InnerHero from "../modules/m14_InnerHero"
import LeaveData from "../modules/m21_leaveData"
import styled from "styled-components"
import { Box, Text } from "@sqymagma/elements"
import m21 from "../data/m21B"
import BlockInner from "../components/blockinner"
import Linkexternal from "../components/linkexternal"

const Section = styled(Box)`
  padding: 24px 0px;
`
const Ul = styled.ul`
  list-style: square;
  margin: 24px 36px;
`
const Li = styled.li`
  padding: 16px 8px;
  list-style: square;
`

const CsslaLink = styled(Linkexternal)`
  color: ${p => p.theme.color.brand01};
  &:hover {
    text-decoration: underline !important;
  }
`

const PrivacyPage = () => {
  return (
    <Layout title="" desc="" keywords="">
      <InnerHero bc="Política de privacidad" title="Política de privacidad" url="privacy" />
      <BlockInner
        mx="auto"
        mt={["24px", "32px", "32px", "64px", "96px"]}
        mb={["48px", "96px", "96px", "128px", "196px"]}
      >
        <Section>
          <Text textStyle="p">
            La presente política de privacidad (la <b>“Política de Privacidad”</b>) tiene por
            finalidad informar, de manera clara y precisa, a los usuarios (en adelante, los
            <b>“Usuarios”</b>) que naveguen por el Sitio Web alojado bajo el nombre de dominio
            www.livingalternatives.es y cualquiera de los subdominios dependientes de este (el
            <b>“Sitio Web”</b>) sobre el tratamiento de los datos de carácter personal recabados por
            la sociedad Living Alternatives, S. L. (en adelante,
            <b>“Living Alternatives”</b>) a través del referido Sitio Web. La presente Política de
            Privacidad es aplicable a los datos recabados a través del Sitio Web, así como a todas
            aquellas actividades de tratamiento de datos personales que realice Living Alternatives
            que, expresamente, remitan a la presente Política de Privacidad para informar al Usuario
            de cómo Living Alternatives trata sus datos personales. Living Alternatives declina
            cualquier responsabilidad sobre las diferentes políticas de privacidad y protección de
            datos de carácter personal que puedan contener las páginas web a las cuales el Usuario
            pueda acceder a través de los hipervínculos ubicados en el Sitio Web y no gestionadas
            directamente por Living Alternatives. De conformidad con lo dispuesto en la normativa
            aplicable, Living Alternatives informa a los Usuarios de que los datos que le faciliten
            a través del Sitio Web o mediante otras fórmulas que remitan a la presente Política de
            Privacidad serán incorporados a sus ficheros de datos, de los que es responsable. La
            cumplimentación del formulario de contacto por parte de los Usuarios, así como los datos
            proporcionados por el Usuario durante la prestación de los servicios, implica
            necesariamente, y sin reservas, el conocimiento y aceptación de la presente Política de
            Privacidad. Por tanto, se recomienda a los Usuarios que, con carácter previo, lean
            detenidamente esta Política de Privacidad.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Identidad y datos de contacto
            </Text>
          </Section>
          <Text textStyle="p">
            Del responsable del tratamiento El titular de este Sitio Web es Living Alternatives S.
            L. con CIF número B88629464, domicilio social en Calle de la Botánica 4, 2º, 28028,
            Madrid e inscrita en el Registro Mercantil de Madrid, Tomo 40479, Folio 61, Hoja
            M-718686.
            <br /> <br />
            Correo electrónico:{" "}
            <CsslaLink href="mailto:hola@livingalternatives.es" title="hola@livingalternatives.es">
              hola@livingalternatives.es
            </CsslaLink>
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Datos necesarios para determinados tratamientos
            </Text>
          </Section>
          <Text textStyle="p">
            La entrega de los datos por parte de los Usuarios mediante el formulario de contacto,
            así como durante la navegación por el Sitio Web es voluntaria, si bien no proporcionar
            determinados datos puede provocar la imposibilidad de acceso a determinados servicios
            prestados por Living Alternatives. Los Usuarios responderán, en cualquier caso, de la
            veracidad, exactitud, vigencia y autenticidad de los datos personales facilitados,
            reservándose Living Alternatives, el derecho a excluir de los servicios a los Usuarios
            que hayan facilitado datos falsos, sin prejuicio de las demás acciones que procedan en
            Derecho.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Información que se recopila de los Usuarios
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives trata los siguientes datos personales de los Usuarios:
            <Ul>
              <Li>Datos identificativos, por ejemplo, nombre y apellidos.</Li>
              <Li> Datos de contacto, como puede ser la dirección de e-mail. </Li>
              <Li>Datos de navegación de los Usuarios en el Sitio Web.</Li>
            </Ul>
            Asimismo, Living Alternatives puede recoger y almacenar datos obtenidos a través del
            Sitio Web mediante la utilización de herramientas como las denominadas cookies, que
            obtienen información de la navegación del Usuario.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Finalidades y bases jurídicas del tratamiento{" "}
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives quiere tratar todos los datos recibidos de forma lícita y para ello
            debe sustentar el tratamiento de los datos del Usuario en una base jurídica suficiente.
            De acuerdo con lo anterior, debajo señalamos con qué finalidades trata Living
            Alternatives los datos y cuál es la base jurídica en la que se asienta cada una de
            ellas:{" "}
            <Ul>
              <Li>
                Living Alternatives utiliza los datos identificativos y de contacto facilitados por
                el Usuario para prestar sus servicios. Este tratamiento de datos es necesario para
                que Living Alternatives pueda prestar sus servicios y llevar a cabo la ejecución del
                contrato entre Living Alternatives y los Usuarios del Sitio Web. En caso de que el
                Usuario no facilite alguno de estos datos, Living Alternatives no estaría en
                disposición de poder prestar alguno de sus servicios.
              </Li>
              <Li>
                Living Alternatives podrá utilizar también los datos identificativos y de contacto
                para tramitar, gestionar y solucionar eventuales quejas o sugerencias o consultas
                del Usuario. Living Alternatives considera que tiene un interés legítimo para
                realizar dicho tratamiento de datos en cuanto que le permite mejorar sus servicios y
                la experiencia de los Usuarios.
              </Li>
              <Li>
                Living Alternatives utiliza los datos identificativos, de contacto y de navegación
                para evaluar el funcionamiento y conocer la opinión sobre los servicios que presta.
                Living Alternatives considera que tiene un interés legítimo para realizar dicho
                tratamiento de datos en cuanto que le permite mejorar sus servicios y la experiencia
                de los Usuarios.{" "}
              </Li>
            </Ul>
            En lo relativo a la información obtenida mediante cookies y dispositivos o mecanismos
            similares, tales como pixels o fragmentos de código, que permitan el almacenamiento y
            recuperación de datos, la base legal estará configurada por el consentimiento tal y como
            queda definido por la normativa de aplicación y las guías orientativas emitidas por la
            Agencia Española de Protección de Datos en la fecha en la que se ha elaborado la
            presente Política de Privacidad, así como y con la finalidad de asegurar el
            funcionamiento correcto de las funcionalidades del Sitio Web que se corresponde con el
            interés legítimo de Living Alternatives.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Aceptación de los Usuarios{" "}
            </Text>
          </Section>
          <Text textStyle="p">
            Mediante la aceptación de los Términos de Uso del Sitio Web y de la presente Política de
            Privacidad, el Usuario reconoce haber leído y entendido las condiciones de uso y los
            términos legales establecidos en la presente Política de Privacidad, y acepta de forma
            expresa e inequívoca la aplicación de las mismas al uso que realice del Sitio Web
            durante su navegación y la prestación de los correspondientes servicios. Los Usuarios se
            declaran expresamente informados y autorizan a Living Alternatives para recabar y tratar
            sus datos para las finalidades indicadas en la presente Política de Privacidad conforme
            en la misma se describe. Los Usuarios deberán notificar a Living Alternatives
            cualesquiera modificaciones que se produzcan en los datos facilitados respondiendo, en
            cualquier caso, de la veracidad y exactitud de los datos suministrados en cada momento.
            Asimismo, los Usuarios deberán abstenerse de cargar cualquier contenido falso o
            inadmisible conforme al Derecho, la moral, la buena fe y el orden público o que pueda,
            de cualquier forma, infringir los derechos de terceros. Living Alternatives se reserva,
            en su caso y sin perjuicio de otras acciones que pudieran corresponderle, el derecho a
            no prestar los servicios, dar de baja o retirar contenidos de aquellos Usuarios que
            faciliten datos falsos o incompletos. Todo ello no implica, en ningún caso, la asunción
            por Living Alternatives de responsabilidad alguna por los daños o perjuicios que
            pudieran derivarse de la falsedad o inexactitud de los datos suministrados o contenidos
            inadecuados, de las que responderá únicamente el Usuario.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Comunicación de datos y transferencias internacionales
            </Text>
          </Section>
          <Text textStyle="p">
            Los datos personales recogidos a través del Sitio Web no serán cedidos a ningún tercero
            para ser utilizados para sus propios fines distintos aquellos para los que el Usuario ha
            consentido su tratamiento. No obstante, podrán acceder a los datos de carácter personal
            de los Usuarios el personal autorizado de Living Alternatives y otros terceros o
            colaboradores que presten servicios a favor de Living Alternatives, con la finalidad de
            gestionar la prestación de los servicios, la relación contractual y/o precontractual con
            los Usuarios. En estos casos en que se permita el acceso a otras compañías
            colaboradoras, dicho acceso a datos por cuenta de terceros será regulado en el
            correspondiente contrato, tal y como se exige en la normativa vigente. Living
            Alternatives, para el tratamiento de los datos personales, podrá utilizar los servicios
            de encargados y subencargados del tratamiento que podrían llegar a alojar fuera de la
            Unión Europea y del Espacio Económico Europeo algunos de sus datos. Lo anterior podría
            implicar una transferencia internacional de datos a países que la Unión Europea
            considera que no tienen aún un nivel de protección de datos adecuado. Por ejemplo, sus
            leyes pueden no garantizarle los mismos derechos, o puede no existir una autoridad de
            control en materia de protección de datos capaz de abordar sus reclamaciones. Sin
            perjuicio de lo anterior, Living Alternatives se preocupa de que, en caso de que se
            compartan datos fuera del Espacio Económico Europeo, las transferencias internacionales
            resultantes queden sujetas a alguna de las garantías adecuadas descritas en la normativa
            aplicable tales como la firma de cláusulas tipo de protección de datos o la sujeción de
            los servicios prestados a un instrumento jurídico vinculante como el Privacy Shield.
            Living Alternatives también podrá revelar los datos personales recabados en los casos
            concretos en que dicha cesión se encuentre establecida y sea exigible por la normativa
            aplicable; o cuando así lo exijan las autoridades competentes.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Plazos de conservación
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives almacena los datos personales de los Usuarios en la medida en que se
            necesiten para las finalidades para los que fueron recabados, y según la base jurídica
            del tratamiento de la misma. Living Alternatives mantendrá los datos personales del
            Usuario mientras exista una relación contractual y/o comercial con el Usuario y mientras
            éste no ejerza los derechos de oposición y/o supresión del tratamiento de los datos
            personales. En estos casos Living Alternatives mantendrá los datos debidamente
            bloqueados, sin darle ningún uso, mientras puedan ser necesarios para el ejercicio o
            defensa de reclamaciones o pueda derivarse algún tipo de responsabilidad judicial, legal
            o contractual de su tratamiento, que deba ser atendida y para lo cual sea necesaria su
            recuperación.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Derechos de los Usuarios
            </Text>
          </Section>
          <Text textStyle="p">
            Cualquier Usuario puede retirar su consentimiento en cualquier momento, cuando el mismo
            se haya otorgado para el tratamiento de sus datos. En ningún caso, la retirada de este
            consentimiento condiciona la ejecución del contrato de servicios o las relaciones
            generadas con anterioridad. Igualmente, en cualquier momento, y/o cuando así lo
            considere conveniente, los Usuarios podrán hacer uso de sus derechos de Acceso,
            Rectificación, Cancelación, Oposición, Portabilidad y Olvido escribiendo al correo
            electrónico de contacto que hemos habilitado para ello: hola@livingalternatives.es;
            adjuntando copia del pasaporte o DNI (titular de los datos) e indicando en el asunto de
            manera expresa la petición que desea realizar: Acceso: A través del ejercicio de este
            derecho puede conocer qué datos de carácter personal suyos están siendo tratados por
            parte de Living Alternatives; su finalidad, el origen o su posible transferencia a
            terceros. Rectificación: Consiste en que pueda modificar sus datos de carácter personal
            que sean inexactos o incompletos, debiendo especificar en la solicitud qué datos desea
            que se modifiquen. Cancelación: Permite la cancelación de sus datos personales por
            inadecuados o excesivos. Oposición: Con el derecho de oposición puede oponerse a que no
            se realice el tratamiento en unos determinados supuestos o para una finalidad concreta,
            como por ejemplo en el hipotético caso de que sus datos se usasen para actividades de
            publicidad y prospección comercial o si el tratamiento tuviese la finalidad de adoptar
            una decisión referida a usted basada únicamente en un tratamiento automatizado de sus
            datos personales. Portabilidad: Podrá recibir sus datos personales facilitados en un
            formato estructurado, de uso común y lectura mecánica, y poder transmitirlos a otro
            responsable, siempre que sea técnicamente posible. Olvido: Podrá solicitar la supresión
            de los datos personales sin dilación debida cuando concurra alguno de los supuestos
            contemplados. Por ejemplo, tratamiento ilícito de datos, o cuando haya desaparecido la
            finalidad que motivó el tratamiento o recogida.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Modificaciones de la política de privacidad{" "}
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives se reserva el derecho a modificar esta Política de Privacidad para
            adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la
            industria y/o comercio. Cualquier modificación en la presente Política de Privacidad se
            informará al Usuario y, en caso de ser necesario, se solicitará su consentimiento. En
            caso de que el Usuario no de su consentimiento para el tratamiento de esos datos, este
            podría no continuar disfrutando de la prestación de los servicios por parte de Living
            Alternatives.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Dudas de los Usuarios
            </Text>
          </Section>
          <Text textStyle="p">
            Si tiene cualquier duda sobre nuestra Política de Privacidad, por favor, contacta con
            nosotros enviando un e-mail a hola@livingalternatives.es . Asimismo, si considera que
            sus derechos no se atienden debidamente, tiene derecho a presentar una reclamación ante
            la Agencia Española de Protección de Datos, cuyos datos de contacto son: Teléfonos:{" "}
            <CsslaLink href="tel:+34901100099" id="TM8-Footer-SecciónContacto-Llamada">
              +34 901 100 099
            </CsslaLink>{" "}
            <CsslaLink href="tel:+34912663517" id="TM8-Footer-SecciónContacto-Llamada">
              +34 912 663 517
            </CsslaLink>{" "}
            ; Dirección Postal:
            <CsslaLink href="https://goo.gl/maps/8Mzq5uRwv4fBxU9k9">
              {" "}
              C/ Jorge Juan, 6 28001-Madrid
            </CsslaLink>
            ; Sede Electrónica:
            <CsslaLink href="https://sedeagpd.gob.es/sede-electronica-web/">
              {" "}
              https://sedeagpd.gob.es/sede-electronica-web/
            </CsslaLink>
            y página web:
            <CsslaLink href="https://www.agpd.es"> www.agpd.es</CsslaLink>
          </Text>
        </Section>
      </BlockInner>
      <LeaveData data={m21} />
      <BigBanner cta="Ver casos de éxito">
        100% de éxito en el cambio de uso de locales comerciales a vivienda
      </BigBanner>
    </Layout>
  )
}

export default PrivacyPage
